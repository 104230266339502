import React from "react";
import BrewList from "../../components/BrewList/BrewList";
import AddButton from "../../components/Buttons/AddButton/AddButton";
import { withRouter } from "react-router";

const BrewListPage: React.FunctionComponent<any> = ({ history }) => {
  return (
    <>
      <BrewList />
      <AddButton onClick={() => history.push("brew/add")} />
    </>
  );
};

export default withRouter(BrewListPage);

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import config from "./firebase/config";
import { FirebaseAppProvider, SuspenseWithPerf } from "reactfire";
import { Typography } from "@material-ui/core";

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={config}>
    <SuspenseWithPerf
      fallback={<Typography>Laden...</Typography>}
      traceId="app-loading"
    >
      <App />
    </SuspenseWithPerf>
  </FirebaseAppProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useState } from "react";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import BrewListPage from "./screens/BrewListPage/BrewListPage";
import "./index.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link as RouterLink,
  Redirect,
} from "react-router-dom";
import LoginPage from "./screens/LoginPage/LoginPage";
import AddBrewPage from "./screens/AddBrewPage/AddBrewPage";
import BrewDetailPage from "./screens/BrewDetailPage/BrewDetailPage";
import { SuspenseWithPerf, AuthCheck } from "reactfire";
import Drawer from "./components/Drawer/Drawer";
import ToDO from "./screens/ToDo/ToDo";

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

const App: React.FunctionComponent<WithStyles<typeof styles>> = (props) => {
  const { classes } = props;

  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <Router>
      <div className={classes.root}>
        <AppBar position="sticky">
          <Toolbar>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              onClick={() => setDrawerOpen(!drawerOpen)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              <RouterLink
                to="/"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                Brouwhulp
              </RouterLink>
            </Typography>
            <AuthCheck fallback={null}>
              <Typography variant="body1" color="inherit">
                Ingelogd
              </Typography>
            </AuthCheck>

            {/* <Button color="inherit">Login</Button> */}
          </Toolbar>
        </AppBar>
        <Drawer
          open={drawerOpen}
          toggleDrawer={() => setDrawerOpen(!drawerOpen)}
        />
        <main>
          <SuspenseWithPerf
            fallback={<Typography variant="body1">laden...</Typography>}
            traceId={"load-burrito-status"}
          >
            <AuthCheck
              fallback={
                <Switch>
                  <Route path="/login" component={LoginPage} />
                  <Redirect from="*" to="/login" />
                </Switch>
              }
            >
              <Switch>
                <Route path="/" exact component={BrewListPage} />
                <Route path="/brew/add" exact component={AddBrewPage} />
                <Route path="/brew/:brewId" exact component={BrewDetailPage} />
                <Route path="/recipes" exact component={ToDO} />
                <Route path="/to-do" exact component={ToDO} />
                <Route path="/tools" exact component={ToDO} />
                <Redirect to="/" />
              </Switch>
            </AuthCheck>
          </SuspenseWithPerf>
        </main>
      </div>
    </Router>
  );
};

export default withStyles(styles)(App);

import React, { FC } from "react";
import {
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  Theme,
} from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import BuildIcon from "@material-ui/icons/Build";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    width: "100%",
    padding: theme.spacing(6),
    boxSizing: "border-box",
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
}));

interface OwnProps {
  open: boolean;
  toggleDrawer: () => void;
}

const Drawer: FC<OwnProps> = ({ open, toggleDrawer }) => {
  const classes = useStyles();
  const history = useHistory();

  const openPage = (page: string) => {
    history.push(page);
    toggleDrawer();
  };
  return (
    <>
      <MuiDrawer anchor="left" open={open} onClose={toggleDrawer}>
        <div className={classes.list} role="presentation">
          <img
            className={classes.logo}
            src={process.env.PUBLIC_URL + "/oudshoorn-logo.png"}
            alt="oudshoorn-logo"
          />
          <List>
            <ListItem button onClick={() => openPage("/brews")}>
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText primary={"Brouwlogs"} />
            </ListItem>
            <ListItem button onClick={() => openPage("/to-do")}>
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary={"Boodschappenlijst"} />
            </ListItem>
            <ListItem button onClick={() => openPage("/recipes")}>
              <ListItemIcon>
                <CollectionsBookmarkIcon />
              </ListItemIcon>
              <ListItemText primary={"Recepten"} />
            </ListItem>
            <ListItem button onClick={() => openPage("/tools")}>
              <ListItemIcon>
                <BuildIcon />
              </ListItemIcon>
              <ListItemText primary={"Tools"} />
            </ListItem>
          </List>
        </div>
      </MuiDrawer>
    </>
  );
};

export default Drawer;

import React from "react";
import {
  withStyles,
  Theme,
  createStyles,
  Fab,
  WithStyles,
} from "@material-ui/core";
import blue from "@material-ui/core/colors/blue";
import AddIcon from "@material-ui/icons/Add";
import { withRouter } from "react-router";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      width: 500,
      position: "relative",
      minHeight: 200,
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    fabBlue: {
      color: theme.palette.common.white,
      backgroundColor: blue[500],
      "&:hover": {
        backgroundColor: blue[600],
      },
    },
  });

const AddButton: React.FunctionComponent<any & WithStyles<typeof styles>> = ({
  classes,
  onClick,
}) => {
  return (
    <Fab className={classes.fab} color="primary" onClick={onClick}>
      <AddIcon />
    </Fab>
  );
};

export default withStyles(styles)(withRouter(AddButton));

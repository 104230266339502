import React, { FunctionComponent } from "react";
import { List, ListItem, ListItemText, Divider } from "@material-ui/core";
import { withRouter } from "react-router";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

const BrewList: FunctionComponent<any> = ({ history }) => {
  // FIRESTORE
  const brewsRef = useFirestore().collection("brews").orderBy("date", "desc");
  const brews = useFirestoreCollectionData(brewsRef, { idField: "id" });

  return (
    <List component="nav">
      {brews.map((brew: any, index: number) => (
        <React.Fragment key={index}>
          <ListItem button onClick={() => history.push(`/brew/${brew.id}`)}>
            <ListItemText primary={brew.name} secondary={brew.date} />
          </ListItem>
          {index + 1 !== brews.length && <Divider />}
        </React.Fragment>
      ))}
    </List>
  );
};

export default withRouter(BrewList);

const show_2Dec = (value: number) => {
    value = Math.round(100*value)/100;
    if(!((100*value) % 100))
      return(value + ".00")
    if(!((100*value) % 10))
      return(value + "0")
    return(value);
  }

const get_SDalcohol = (Dstart: number, Dend: number) => {
    const dD = Dstart - Dend;
    const fact = (0.8600 - 0.8374) / 0.112; 
    return  (0.8374 + dD*fact);
  }

const removeDots = (val: string): number => +val.split(".").join("");

const calcAlc = (SGstart: number | string, SGend: number | string) => {
    // const SGstart = 1.068;
    // const SGend = 1.021;
    SGstart = removeDots(SGstart+"");
    SGend = removeDots(SGend+"");

    const Dalcohol = 0.7894; // Soortelijke massa alcohol
    const Dwater = 1; // Soortelijk gewicht van water
    const SDsuiker = 1.552*1.04317; // Schijnbaar soortelijke massa van suiker in oplossing
    const SAfactor = 0.484; // Omzettingsverhouding Suiker -> Alcohol (massa)

    // Dichtheid in gram/liter voor vergisting
    const Dtotaal = SGstart / 1000;
    // Dichtheid in gram/liter na vergisting
    const Deind = SGend / 1000;
    // Bereken bij deze dichtheden de schijnbare dichtheid van Alcohol
    const SDalcohol = get_SDalcohol(Dtotaal, Deind);
    // ga uit van 100 ml (Brix)
    const Vtotaal = 100;
    // De massa suiker voor vergisting
    const Msuiker = Vtotaal*(Dtotaal-Dwater)/(1-Dwater/SDsuiker);
    // Massa van het water in de oplossing. Dit blijft constant.
    const Mwater = Vtotaal * Dtotaal - Msuiker;
    // Massa van de restsuiker na hoofdvergisting
    const Mrestsuiker =
      (Mwater / Dwater +
        (SAfactor * Msuiker) / SDalcohol -
        (Mwater + SAfactor * Msuiker) / Deind) /
      ((1 - SAfactor) / Deind - 1 / SDsuiker + SAfactor / SDalcohol);
      // Massa van de alcohol na hoofdvergisting
    const Malcohol = SAfactor * (Msuiker - Mrestsuiker);
    // Volumepercentage alcohol na hoofdvergisting
    const VPalcohol =
      (100 * (Malcohol / Dalcohol)) /
      ((Mrestsuiker + Mwater + Malcohol) / Deind);

      return show_2Dec(VPalcohol);
  };

  export { calcAlc }
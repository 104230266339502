import React, { useState } from "react";
import {
  Typography,
  Button,
  TextField,
  Grid,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { useFirestore } from "reactfire";

const styles = (theme: Theme) => ({
  gridItem: {
    padding: theme.spacing(2),
  },
});

const AddBrewPage: React.FunctionComponent<WithStyles<typeof styles> & any> = ({
  classes,
  history,
}) => {
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const brewsRef = useFirestore().collection("brews");

  const onSubmit = (event: any) => {
    event.preventDefault();
    brewsRef
      .add({
        name: name,
        date: date,
      })
      .then((res: any) => {
        history.push(`/brew/${res.id}`);
      })
      .catch((e: any) => {
        alert(e.message);
      });
  };
  return (
    <Grid container>
      <Grid item xs className={classes.gridItem}>
        <Typography variant="subtitle1">Voeg brouw toe</Typography>
        <form onSubmit={onSubmit}>
          <TextField
            // id="standard-name"
            label="Naam"
            // className={classes.textField}
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
            fullWidth
          />
          <TextField
            // id="standard-password"
            label="Datum"
            type="date"
            // className={classes.textField}
            value={date}
            onChange={(e) => setDate(e.target.value)}
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
          >
            Aanmaken
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(AddBrewPage);

import React, { useState } from "react";
import { TextField, Typography, Button } from "@material-ui/core";
import { useAuth } from "reactfire";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const auth = useAuth();

  const onSubmit = (event: any) => {
    auth
      .signInWithEmailAndPassword(email, password)
      .then((a: any) => {
        console.log(a);
      })
      .catch((error: any) => {
        console.log(error);
      });

    event.preventDefault();
  };

  return (
    <div>
      <Typography variant="subtitle1">Login</Typography>
      <form onSubmit={onSubmit}>
        <TextField
          // id="standard-name"
          label="Email"
          // className={classes.textField}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          fullWidth
        />
        <TextField
          // id="standard-password"
          label="Password"
          type="password"
          // className={classes.textField}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
          fullWidth
        />
        <Button variant="contained" size="large" color="primary" type="submit">
          Inloggen
        </Button>
      </form>
    </div>
  );
};

export default Login;

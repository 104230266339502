import React, { useRef, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Theme,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  makeStyles,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import moment from "moment";
import {
  useFirestore,
  useFirestoreDocData,
  useFirestoreCollectionData,
} from "reactfire";
import { useParams } from "react-router";
import AddYeastLogDialog from "../../components/Dialogs/AddYeastLogDialog/AddYeastLogDialog";
import { LineChart, YAxis, CartesianGrid, XAxis, Line } from "recharts";
import { calcAlc } from "../../services/formulas";
import ThrashIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: Theme) => ({
  gridItem: {
    padding: theme.spacing(2),
  },
  graph: {
    marginTop: theme.spacing(2),
  },
  greyedOut: {
    opacity: 0.5,
  },
}));

const BrewDetailPage = () => {
  const graphContainer = useRef<HTMLDivElement>(document.createElement("div"));
  const [graphWidth, setGraphWidth] = useState(500);
  const classes = useStyles();
  const { brewId } = useParams<{ brewId: string }>();
  const brewRef = useFirestore().collection("brews").doc(brewId);
  const brew: any = useFirestoreDocData(brewRef);
  const deletedLog = useRef();
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const yeastLogsRef = useFirestore().collection("yeast-logs");

  const yeastLogRefBrew = yeastLogsRef
    .where("brew", "==", brewId)
    .orderBy("date", "asc");
  const yeastLogs: any = useFirestoreCollectionData(yeastLogRefBrew, {
    idField: "id",
  });

  useEffect(() => {
    graphContainer.current &&
      setGraphWidth(graphContainer.current.clientWidth - 60);
  }, [graphContainer]);

  let fermentingDays;
  if (yeastLogs.length > 1) {
    const startDate = yeastLogs[0].date.seconds * 1000;
    const endDate = yeastLogs[yeastLogs.length - 1].date.seconds * 1000;
    fermentingDays = [
      ...Array(moment(endDate).diff(moment(startDate), "days") + 1),
    ].map((x, i) => startDate + 86400000 * i);
  }

  const finishFermenting = () => {
    const alc = calcAlc(
      yeastLogs[0].hydroSG,
      yeastLogs[yeastLogs.length - 1].hydroSG
    );
    brewRef.set({
      ...brew,
      alc,
    });
  };

  const deleteLog = (log: any) => {
    handleClose();
    deletedLog.current = log;
    yeastLogsRef.doc(log.id).delete();
    setTimeout(() => {
      setSnackBarOpen(true);
    }, 100);
  };

  const handleClose = () => {
    setSnackBarOpen(false);
  };

  const undoDelete = (log: any) => {
    yeastLogsRef.add({ ...log });
    setSnackBarOpen(false);
  };

  return (
    <>
      <Grid container>
        <Grid item xs className={classes.gridItem} ref={graphContainer}>
          <Typography variant="h2">{brew.name}</Typography>
          <Typography variant="subtitle1" gutterBottom>
            <i>{brew.date}</i>
          </Typography>
          <Typography>
            Alc:{" "}
            {brew.alc ? (
              `${brew.alc}%`
            ) : (
              <>
                {"- "}
                <span className={classes.greyedOut}>
                  <i>(vergisting nog niet voltooid)</i>
                </span>
              </>
            )}
          </Typography>
          <hr />
          <Typography variant="h4">
            Vergisting {brew.alc && "(voltooid)"}
          </Typography>
          {yeastLogs.length > 0 ? (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Datum</TableCell>
                    <TableCell>SG</TableCell>
                    <TableCell>BRIX</TableCell>
                    {!brew.alc && <TableCell></TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {yeastLogs.map((log: any) => (
                    <TableRow>
                      <TableCell>
                        {moment(log.date.seconds * 1000).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell>{log.hydroSG}</TableCell>
                      <TableCell>{log.refractoBrix}</TableCell>
                      {!brew.alc && (
                        <TableCell align="right">
                          <IconButton onClick={() => deleteLog(log)}>
                            <ThrashIcon />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                  {!brew.alc && (
                    <TableRow>
                      <TableCell colSpan={!brew.alc ? 4 : 3} align="right">
                        <AddYeastLogDialog />
                        <Button
                          onClick={finishFermenting}
                          disabled={yeastLogs.length < 2}
                        >
                          Voltooi vergisting
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {fermentingDays && (
                <LineChart
                  width={graphWidth}
                  height={300}
                  data={yeastLogs.map((log: any) => {
                    return {
                      ...log,
                      date: log.date.seconds * 1000,
                    };
                  })}
                  className={classes.graph}
                >
                  <XAxis
                    dataKey="date"
                    padding={{ left: 20, right: 20 }}
                    type="number"
                    ticks={fermentingDays}
                    domain={[
                      fermentingDays[0],
                      fermentingDays[fermentingDays.length - 1],
                    ]}
                    tickFormatter={(value) => moment(value).format("DD-MM")}
                  />
                  <YAxis type="number" domain={[1, 1.1]} />
                  <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                  <Line type="monotone" dataKey="hydroSG" stroke="#8884d8" />
                </LineChart>
              )}
            </>
          ) : (
            <div style={{ marginTop: 20 }}>
              <AddYeastLogDialog />
            </div>
          )}
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snackBarOpen}
        autoHideDuration={8000}
        onClose={handleClose}
        message="Log deleted"
        action={
          <React.Fragment>
            <Button
              color="secondary"
              size="small"
              onClick={() => undoDelete(deletedLog.current)}
            >
              UNDO
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
};

export default BrewDetailPage;
